import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import RefreshIcon from '@mui/icons-material/Refresh';


import MainCard from '../../components/cards/MainCard'

import { useGetPolishListQuery } from '../../api/apiSlice'


export const PolishList = () => {
    const {
        data: polishList = [],
        refetch,
        isFetching
    } = useGetPolishListQuery()

    // const waitReload = () => {
    //     if (waiting) {
    //         return
    //     }
    //     setTimeout(() => {
    //         setWating(false)
    //         refetch()
    //     }, 1000 * 10);
    // }
    // // setInterval(() => {
    // //     refetch()
    // // }, 1000);

    return (
        <MainCard title="降重结果" secondary={
            <Button startIcon={<RefreshIcon />} color='secondary' onClick={refetch}>
                点击查看降重结果
            </Button>}
        >
            <Typography variant="body2" color={"gray"} mb={2}>
                降重结果由用户输入内容结合人工智能自动生成，请在政策法律允许范围内使用本服务及生成的内容。如有不恰当内容，请联系我们删除。
            </Typography>
            {
                isFetching ? '加载中...' :
                    polishList.length === 0 ?
                        '无内容' :
                        <Stack spacing={3}>
                            {polishList.slice(0).reverse().map((obj, i) =>
                                <Container key={i}>
                                    <Stack spacing={2}>
                                        <Typography variant="h6" gutterBottom>
                                            {new Date(obj.created).toLocaleString()}
                                        </Typography>
                                        <TextField
                                            label="原文"
                                            multiline
                                            minRows={3}
                                            maxRows={100}
                                            fullWidth
                                            value={obj.text}
                                            focused
                                        />
                                        <TextField
                                            color="success"
                                            label={obj.aitext.length > 0 ? '优化后' : '正在处理，大约需时1分钟'}
                                            multiline
                                            minRows={3}
                                            maxRows={100}
                                            fullWidth
                                            value={obj.aitext}
                                            focused={obj.aitext.length > 0}
                                        />
                                        <Divider />
                                    </Stack >
                                </Container >
                            )}
                        </Stack >
            }</MainCard >
    )
}
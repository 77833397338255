import { useState, useRef } from 'react'


import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import MainCard from '../../components/cards/MainCard'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import Typography from '@mui/material/Typography'
import { Snackbar, Alert } from '@mui/material'

import { useAddPolishMutation } from '../../api/apiSlice'
import AlertDialog from '../../components/AlertDialog'

export const AddPolish = ({ balancePolish }) => {
    const [newText, setNewText] = useState('')
    const [newTextReady, setNewTextReady] = useState('')
    const [textErrorMsg, setTextErrorMsg] = useState('')
    const newTextRef = useRef()
    const [addedSuccess, setAddedSuccess] = useState(false)

    const getTextLength = (str) => {
        var len = 0;
        for (let i = 0; i < str.length; i++) {
            var length = str.charCodeAt(i);
            if (length > 255) {
                len += 2
            } else {
                len += 1
            }
        }
        return len
    }

    const filterText = (value) => value.replaceAll(/[\r\n]/g, '').replaceAll(/\[\d*\]/g, '').trim()
    const textLenOk = (value) => {
        const len = getTextLength(value)
        if (len > 400) {
            setTextErrorMsg(`总字符数${len}（一个中文字符长度等于两个英文字符），请删减后再试。`)
            return false
        } else if (len < 50) {
            setTextErrorMsg(`为了优化降重质量，不要输入过短的内容，请继续输入。`)
            return false
        }
        return true
    }

    const onNewTextChanged = (e) => {

        setNewText(e.target.value)

        const value = filterText(e.target.value)
        const ok = textLenOk(value)
        if (ok && textErrorMsg !== '') {
            setTextErrorMsg('')
        }
    }

    const onAddNewText = (e) => {
        const value = filterText(newText)
        const ok = textLenOk(value)
        if (!ok) {
            newTextRef.current.focus()
            return false
        }
        setNewTextReady(value)
        setOpenDialog(true)
    }

    const [openDialog, setOpenDialog] = useState(false)

    const onConfirmClose = () => {
        setOpenDialog(false)
    }

    const alertRef = useRef()
    const sysAlert = () => {
        alertRef.current.alert('系统故障', '请刷新网页重试，如故障依旧请联系QQ：1915178792', () => window.location.reload())
    }

    const [addPolish] = useAddPolishMutation()
    const onConfirmYes = async () => {
        let data
        try {
            data = await addPolish({ Text: newTextReady }).unwrap()
        } catch (err) {
            if (window.location.href.endsWith('debug')) {
                console.log(err)
                alert(JSON.stringify(err))
            }
            sysAlert()
            return
        }
        if (data === 'ErrBalanceNotEnough') {
            alertRef.current.alert('余额不足', '请充值后重试，如充值后依然无法使用可联系QQ：1915178792')
            return
        }
        setAddedSuccess(true)
        onConfirmClose()
        setNewText('')
        setNewTextReady('')
    }
    const onAddedClose = () => {
        setAddedSuccess(false)
    }

    const confirmDialog = <Dialog
        open={openDialog}
        onClose={onConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            确定对以下内容进行降重吗？
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {newTextReady}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onConfirmClose}>取消</Button>
            <Button onClick={onConfirmYes} autoFocus>
                确定
            </Button>
        </DialogActions>
    </Dialog>

    return (
        <MainCard title="开始降重">
            <Typography variant="body2" color={"gray"} mb={2}>
                请勿输入违反政策法律的内容，如有发现，上报国家相关部门处理。
            </Typography>
            <Stack spacing={2}>
                <TextField
                    label="待降重内容"
                    error={textErrorMsg.length > 0}
                    helperText={textErrorMsg.length > 0 ? textErrorMsg : '每条待降重内容可输200个中文或400个英文字符。为了优化降重质量，请不要输入过短或过长的内容。'}
                    multiline
                    minRows={3}
                    maxRows={100}
                    fullWidth
                    inputRef={newTextRef}
                    onChange={onNewTextChanged}
                    value={newText}
                />
                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    onClick={onAddNewText}
                    disabled={balancePolish < 1}
                >
                    {balancePolish < 1 ? '余额不足，请充值后再降重' : '提交'}
                </Button>
            </Stack>
            {confirmDialog}
            <AlertDialog ref={alertRef} />
            <Snackbar onClose={onAddedClose} open={addedSuccess} autoHideDuration={5000} >
                <Alert onClose={onAddedClose} severity="success" sx={{ width: '100%' }}>
                    提交成功，请稍等一分钟后查看结果。
                </Alert>
            </Snackbar>
        </MainCard>
    )
}
import React from 'react';
// import { useSelector } from 'react-redux'
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'
// import './App.css'
import CssBaseline from '@mui/material/CssBaseline'

import { PrivateRoutes } from './features/auth/PrivateRoutes';
import { Login } from './features/auth/Login';

import Copyright from './components/Copyright'

import { Home } from './features/home/Home';
import { Payment } from './features/payment/Payment';

function App() {

  return (
    <BrowserRouter>
      <CssBaseline />
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route exact path="/" element={<Home />} />
          <Route path="/payment" element={<Payment />} />
        </Route>
        <Route path='/login' element={<Login />} />
      </Routes>
      <Copyright mt={8} mb={4} />
    </BrowserRouter>
  )
}

export default App;

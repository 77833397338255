import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import { Balance } from './Balance'
import { AddPolish } from './AddPolish'
import { PolishList } from './PolishList'
import { useGetBalanceQuery } from '../../api/apiSlice'

export const Home = () => {
    const {
        data: balance = { Polish: 0 },
    } = useGetBalanceQuery()

    return (
        <Container component="main" maxWidth="md" >
            <Stack spacing={3}>
                <Typography component="h4" align='center' variant="h4" mt={2}>
                    爱写写 - 论文降重
                </Typography>
                <Balance balancePolish={balance.Polish} />
                <AddPolish balancePolish={balance.Polish} />
                <PolishList />
            </Stack>
        </Container >
    )
}


import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({ baseUrl: '/api' })
const baseQueryWithAuth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        window.location.href = '/login'
        return
    }
    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithAuth,
    tagTypes: ['Polish', 'Balance'],
    endpoints: (builder) => ({
        getPolishList: builder.query({
            query: () => '/polish',
            providesTags: (result = [], error, arg) => [
                'Polish',
                ...result.map(({ id }) => ({ type: 'Polish', id })),
            ],
        }),
        getPolish: builder.query({
            query: id => `/polish/${id}`,
            providesTags: (result, error, arg) => [{ type: 'Polish', id: arg }],
        }),
        addPolish: builder.mutation({
            query: data => ({
                url: '/polish',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Polish', 'Balance'],
        }),
        getBalance: builder.query({
            query: () => '/account/balance',
            providesTags: (result = [], error, arg) => [
                'Balance',
            ],
        }),
        sendAuthSms: builder.mutation({
            query: data => ({
                url: '/auth/sms',
                method: 'POST',
                body: data
            }),
        }),
        loginAuth: builder.mutation({
            query: data => ({
                url: '/auth/login',
                method: 'PUT',
                body: data
            }),
        }),
        addOrder: builder.mutation({
            query: data => ({
                url: '/order',
                method: 'POST',
                body: data
            }),
        }),
    })
})

export const {
    useGetPolishListQuery,
    useGetPolishQuery,
    useAddPolishMutation,
    useGetBalanceQuery,
    useSendAuthSmsMutation,
    useLoginAuthMutation,
    useAddOrderMutation,
} = apiSlice
import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'

import { useLoginAuthMutation, useSendAuthSmsMutation } from '../../api/apiSlice'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertDialog from '../../components/AlertDialog'

export const Login = () => {

    const navigate = useNavigate()

    const captchaRef = useRef()
    const smscodeRef = useRef()

    const [phone, setPhone] = useState('')
    const [phoneError, setPhoneError] = useState(false)
    const [smscodeAlert, setSmscodeAlert] = useState(false)

    const alertRef = useRef()
    const sysAlert = () => {
        alertRef.current.alert('系统故障', '请刷新网页重试，如故障依旧请联系QQ：1915178792', () => window.location.reload())
    }

    const onPhoneChanged = (e) => {
        setPhone(e.target.value)

        // 修改手机号后需要重新验证Captcha并发送验证码才可以重新登录
        setShowLogin(false)

        if (phoneError && /^[0-9]{11}$/.test(e.target.value)) {
            setPhoneError(false)
        }
    }

    const onPhoneBlur = (e) => {
        if (!/^[0-9]{11}$/.test(e.target.value)) {
            setPhoneError(true)
        }
    }

    const [smscode, setSmscode] = useState('')
    const [smscodeError, setSmscodeError] = useState(false)
    const onSmsCodeChanged = (e) => {
        setSmscode(e.target.value)

        if (smscodeError && /^[0-9]{6}$/.test(e.target.value)) {
            setSmscodeError(false)
        }
    }
    const onSmscodeBlur = (e) => {
        if (!/^[0-9]{6}$/.test(e.target.value)) {
            setSmscodeError(true)
        }
    }

    const [showLogin, setShowLogin] = useState(false)
    const [hcToken, setHcToken] = useState('')
    const [smsTimeout, setSmsTimeout] = useState(0)

    const [sendAuthSms] = useSendAuthSmsMutation()
    const onSmscodeSend = async () => {
        if (phoneError) {
            return
        }
        // 为保障体验：下面的代码顺序不可以修改
        // 保存token以备后用
        const token = hcToken

        // 清空token，这样可以立即禁用发送短信按钮并在await结束之前不会再次发送短信
        setHcToken('')
        try {
            await sendAuthSms({ Captcha: token, Phone: phone }).unwrap()
        } catch (err) {
            if (err.status === 400) {
                alertRef.current.alert('验证码发送失败', '可能是此手机号发送太频繁或手机号异常，如重试后依然失败可联系QQ：1915178792', () => window.location.reload())
            } else {
                sysAlert()
            }
            return
        }
        setSmsTimeout(60)
        setShowLogin(true)
        captchaRef.current.resetCaptcha()
        const smsInterval = window.setInterval(() => {
            setSmsTimeout(o => {
                const i = o - 1
                if (i === 0) {
                    window.clearInterval(smsInterval)
                }
                return i
            })
        }, 1000)
    }

    const [loginAuth] = useLoginAuthMutation()
    const onLoginClicked = async () => {
        if (smscode.length !== 6) {
            smscodeRef.current.focus()
            setSmscodeError(true)
            return
        }
        if (smscodeError || phoneError) {
            return
        }
        let data
        try {
            data = await loginAuth({ smscode: smscode }).unwrap()
        } catch (err) {
            sysAlert()
            return
        }
        if (data !== 'success') {
            // alertRef.current.alert('验证码错误', '请输入正确的6位数字验证码，或重新发送验证码')
            setSmscodeAlert(true)
            smscodeRef.current.focus()
            return
        }
        navigate('/')
    }

    const handleVerificationSuccess = (token, ekey) => {
        setHcToken(token)
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" align='center' variant="h4" >
                    爱写写 - 论文降重
                </Typography>
                <Typography component="subtitle1" color={'gray'} align='center' mt={2} >
                    本毕业季已结束，暂停新用户注册，老用户也请尽快使用完您的额度，我们相约明年的毕业季。
                </Typography>
                {/* <Typography component="subtitle1" color={'gray'} align='center' mt={2} >
                    首次登录赠送免费试用额度
                </Typography>
                <Typography component="subtitle1" color={'gray'} align='center' mb={2} >
                    联系客服参加活动再赠送至少2000字额度
                </Typography> */}
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} >
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h2" variant="h5">
                    登 录
                </Typography>
                <Box noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="手机号"
                        type='tel'
                        error={phoneError}
                        helperText={phoneError ? '请输入11位手机号' : ''}
                        value={phone}
                        onChange={onPhoneChanged}
                        onBlur={onPhoneBlur}
                        autoFocus
                    />
                    <HCaptcha
                        sitekey="cd7d930d-7401-4bda-9c72-852818dc7316"
                        onVerify={(token, ekey) => handleVerificationSuccess(token, ekey)}
                        ref={captchaRef}
                    />
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!(hcToken.length > 0 && smsTimeout === 0)}
                        onClick={onSmscodeSend}
                    >
                        {smsTimeout === 0 ? '发送短信验证码' : `已发送，重发倒计时${smsTimeout}秒`}
                    </Button>
                    <Box hidden={!showLogin}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="短信验证码"
                            type='tel'
                            error={smscodeError}
                            helperText={smscodeError ? '请输入6位验证码' : ''}
                            onBlur={onSmscodeBlur}
                            value={smscode}
                            onChange={onSmsCodeChanged}
                            inputRef={smscodeRef}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={onLoginClicked}
                        >
                            登 录
                        </Button>
                        <Box><Link variant="body2" sx={{ color: 'gray' }} href='/licenses.txt' target='_blank'>登录即代表同意本用户协议</Link></Box>
                    </Box>
                    <AlertDialog ref={alertRef} />
                    <Snackbar open={smscodeAlert} autoHideDuration={5000} onClose={() => setSmscodeAlert(false)} >
                        <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
                            验证码错误，请输入正确的6位数字验证码，或重新发送验证码。
                        </Alert>
                    </Snackbar>
                    {/* <Grid container>
                        <Grid item xs>
                            <AlertModal title={'登录问题？'} />
                        </Grid>
                    </Grid> */}
                </Box>
            </Box>
        </Container>
    )
}
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

export default function Copyright(props) {
    return (
        <Box {...props}>
            <Typography variant="body2" color="text.secondary" align="center" >
                如果您有任何疑问，请联系我们
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center" >
                QQ：1️⃣9️⃣1️⃣5️⃣1️⃣7️⃣8️⃣7️⃣9️⃣2️⃣
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center" mt={2} >

                {'Copyright © '}
                <Link color="inherit" href="https://aithesis.cn">
                    aithesis.cn
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </Box>


    );
}

import React, { useState, useRef } from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import {
    Card, CardContent, CardHeader, Grid, CardActionArea, Button, Breadcrumbs, Link, Box,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@mui/material'
import { useAddOrderMutation } from '../../api/apiSlice'
import AlertDialog from '../../components/AlertDialog'

export const Payment = () => {
    const plans = [
        {
            name: '体验版降重包',
            price: 19,
            code: 5,
            features: [
                // '效果不满意？送3条附降包！',
                '适合首次体验性购买',
            ]
        },
        {
            name: '标准版降重包',
            price: 69,
            code: 20,
            features: [
                // '效果不满意？送10条附降包！',
                '约9.0折（每条相比体验版）',
            ]
        },
        {
            name: '加量版降重包',
            price: 159,
            code: 50,
            features: [
                // '效果不满意？送25条附降包！',
                '约8.3折（每条相比体验版）',
            ]
        }
    ]

    const [confirm, setConfirm] = useState(false)
    const [currentKey, setCurrentKey] = useState(0)
    const [paying, setPaying] = useState(false)

    const onCloseConfirm = () => {
        setConfirm(false)
    }
    const openConfirm = (key) => {
        setCurrentKey(key)
        setConfirm(true)
    }

    const [addOrder] = useAddOrderMutation()

    const alertRef = useRef()
    const sysAlert = () => {
        alertRef.current.alert('系统故障', '请刷新网页重试，如故障依旧请联系QQ：1915178792')
    }
    const onPayment = async () => {
        setPaying(true)
        const code = plans[currentKey].code
        var data
        try {
            data = await addOrder({ ProductCode: code }).unwrap()
        } catch (err) {
            sysAlert()
            window.location.reload()
            return
        }
        // window.open(data, '_blank')
        window.location.href = data

        onCloseConfirm()
        setPaying(false)
    }
    return (
        <Container>
            <Breadcrumbs aria-label="breadcrumb" mt={1}>
                <Link underline="hover" color="inherit" href="/">
                    降重
                </Link>
                <Typography color="text.primary">支付</Typography>
            </Breadcrumbs>
            <Typography component="h5" align='center' variant="h5" mt={2}>
                购买降重包
            </Typography>
            <Grid container spacing={3} mt={2}>
                {plans.map((plan, key) => (
                    <Grid item sm={4} xs={12}>
                        <Card >
                            <CardActionArea>
                                <CardHeader title={plan.name} />
                                <CardContent>
                                    {/* <Typography variant="h5" component="h5">
                                        {plan.price}元
                                    </Typography> */}
                                    <Stack spacing={1}>
                                        <Typography variant="body1" component="p">
                                            <b>{plan.price}元</b>
                                        </Typography>
                                        <Typography variant="body1" component="p">
                                            <b>{plan.code}条</b> × 200中文字符（400英文字符）
                                        </Typography>
                                        {plan.features.map((feature) => (
                                            <Typography variant="body1" component="p">
                                                {feature}
                                            </Typography>
                                        ))}
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            onClick={() => openConfirm(key)}
                                        >
                                            购买
                                        </Button>
                                    </Stack>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Box mt={1}>
                <Link href="/">
                    [返回降重]
                </Link>
            </Box>
            <Dialog
                open={confirm}
                onClose={onCloseConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    确认付款信息
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Stack spacing={2}>
                            <Typography variant="h6" component="h6">
                                产品信息：{plans[currentKey].name}
                            </Typography>
                            <Typography variant="h6" component="h6" >
                                产品价格：{plans[currentKey].price}元
                            </Typography>
                            <Typography variant="h6" component="h6" >
                                付款方式：<img src='/alipay_icon.png' height={22} alt='支付宝' />
                            </Typography>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseConfirm}>取消</Button>
                    <Button onClick={onPayment}
                        disabled={paying}
                        variant="contained"
                        autoFocus>
                        {paying ? '处理中...' : '立即支付'}
                    </Button>
                </DialogActions>
            </Dialog>
            <AlertDialog ref={alertRef} />
        </Container >
    )
}

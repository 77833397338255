import { forwardRef, useState, useImperativeHandle } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Container } from '@mui/material'

const AlertDialog = forwardRef((props, ref) => {

    const [open, setOpen] = useState(false)
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [onClose, setOnClose] = useState(() => () => setOpen(false))

    useImperativeHandle(ref, () => ({
        alert(t, c, onClose) {
            setTitle(t ?? '')
            setContent(c ?? '')
            setOpen(true)
            if (onClose) {
                setOnClose(() => () => {
                    onClose()
                    setOpen(false)
                })
            }
        }
    }))

    return (
        <Container>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {title !== '' ?
                    <DialogTitle id="alert-dialog-title">
                        {title}
                    </DialogTitle>
                    : null
                }
                {content !== '' ?
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {content}
                        </DialogContentText>
                    </DialogContent>
                    : null
                }
            </Dialog>
        </Container>
    )
})

export default AlertDialog